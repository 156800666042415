/* .VersionHeader {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #222;
	color: #fff;
	font-family: Arial, Helvetica, sans-serif;
} */

.TVChartContainer {
	height: 400px;
}
.apply-common-tooltip{
	display: none !important;
}
.statusesWrapper-GnubQvDb {
	display: none;
}
@media only screen and (min-width:1791px){
	.TVChartContainer {
		height:75vh ;
        margin-bottom:0px;
	}
}
  
